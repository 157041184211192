import { identificationType, bank, consumerType } from "@/enums";
import { jsonToArray } from "@/utils/array";

let identificationTypeOptions = jsonToArray(identificationType, (e) => ({
    id: e,
    name: e
}));
let bankOptions = jsonToArray(bank, (e) => ({
    id: e,
    name: e
}));
let consumerTypeOptions = jsonToArray(consumerType, (e) => ({
    id: e,
    name: e
}));

function getIdNoLabel(idType) {
    switch (idType) {
        case identificationType.NRIC:
            return "IC No.";
        case identificationType.PASSPORT:
            return "Passport No.";
        default:
            return "Select an identification type";
    }
}

export {
    identificationTypeOptions,
    bankOptions,
    consumerTypeOptions,
    getIdNoLabel
};
